import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0915\u0941\u0928\u0948\u0020\u092e\u094d\u092f\u093e\u091a\u0020\u092b\u0947\u0932\u093e\u0020\u092a\u0930\u0947\u0928",
        messages: {
            required: '\u0915\u094d\u0937\u0947\u0924\u094d\u0930\u0020\u092d\u0930\u094d\u0928\u0941\u0939\u094b\u0938\u094d',
            phone: {
                incorrect: '\u0917\u0932\u0924\u0020\u092b\u094b\u0928\u0020\u0928\u092e\u094d\u092c\u0930'
            },
            email: {
                incorrect: '\u0917\u0932\u0924\u0020\u0920\u0947\u0917\u093e\u0928\u093e\u0020\u0922\u093e\u0901\u091a\u093e',
                symbolNotFound: '\u0920\u0947\u0917\u093e\u0928\u093e\u092e\u093e\u0020\u0935\u0930\u094d\u0923\u0020 "@" \u0938\u092e\u093e\u0935\u0947\u0936\u0020\u0939\u0941\u0928\u0941\u092a\u0930\u094d\u091b\u0964\u0020\u0920\u0947\u0917\u093e\u0928\u093e\u092e\u093e\u0020\u0938\u0919\u094d\u0915\u0947\u0924\u0020 "@" \u091b\u0941\u091f\u0947\u0915\u094b\u0020\u091b',
                addressIsNotFull: '"@" \u0935\u0930\u094d\u0923\u092a\u091b\u093f\u0020\u0920\u0947\u0917\u093e\u0928\u093e\u0915\u094b\u0020\u092d\u093e\u0917\u0020\u092d\u0930\u094d\u0928\u0941\u0939\u094b\u0938\u094d\u0964\u0020\u0920\u0947\u0917\u093e\u0928\u093e\u0020\u092a\u0942\u0930\u093e\u0020\u091b\u0948\u0928\u0964'
            },
            password: {
                minLength: '\u0905\u0928\u0941\u092e\u0924\u093f\u0020\u092a\u094d\u0930\u093e\u092a\u094d\u0924\u0020\u0935\u0930\u094d\u0923\u0939\u0930\u0942\u0915\u094b\u0020\u0928\u094d\u092f\u0942\u0928\u0924\u092e\u0020\u0938\u0919\u094d\u0916\u094d\u092f\u093e: 6. \u0020\u0939\u094b\u0964\u0020\u0905\u0939\u093f\u0932\u0947: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u092a\u094d\u0930\u0935\u093f\u0937\u094d\u091f\u0020\u0917\u0930\u093f\u090f\u0915\u094b\u0020\u092a\u093e\u0938\u0935\u0930\u094d\u0921\u0020\u092e\u093f\u0932\u0947\u0928'
            },
            ofertaAgreement: {
                notChecked: '\u0924\u092a\u093e\u0908\u0902\u0932\u0947\u0020\u0928\u093f\u092f\u092e\u0939\u0930\u0942\u0020\u0938\u094d\u0935\u0940\u0915\u093e\u0930\u0020\u0917\u0930\u094d\u0928\u0941\u092d\u090f\u0915\u094b\u0020\u092a\u0941\u0937\u094d\u091f\u093f\u0020\u0917\u0930\u094d\u0928\u0941\u0939\u094b\u0938\u094d'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});